import { ref } from 'vue'

import { Auth } from '@/services/auth'

const account = ref(null)

async function initializeAuth() {
  if (account.value != null) {
    return
  }
  account.value = await Auth.initialize()
  return account.value
}

function login() {
  return Auth.login()
}

async function logout() {
  account.value = null
  await Auth.logout()
}

export function useAuth() {
  return {
    account,
    initializeAuth,
    login,
    logout,
  }
}
