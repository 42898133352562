import {
  browserLocalPersistence,
  getAuth,
  getIdToken,
  getRedirectResult,
  OAuthProvider,
  setPersistence,
  signInWithRedirect,
  signOut,
} from 'firebase/auth'

import { firebaseApp } from '@/plugins/db'

const PROVIDER_ID_MICROSOFT = 'microsoft.com'

let authInstance = null
const authProvider = new OAuthProvider(PROVIDER_ID_MICROSOFT)

authProvider.setCustomParameters({
  tenant: import.meta.env.VITE_AUTH_TENANT_ID,
  // needed to prevent auto-login
  prompt: 'select_account',
})

/**
 * Auth service
 */
export const Auth = {
  /**
   * Initializes auth instance. Must always be called before any other method.
   * @returns {User | null} - The user object if the user is logged in. Otherwise it will do a login-redirect (won't actually return null).
   */
  async initialize() {
    authInstance = await getAuth(firebaseApp)
    throwIfNoAuthInstance(authInstance)
    await setPersistence(authInstance, browserLocalPersistence)
    await authInstance.authStateReady()
    const maybeUser = authInstance.currentUser
    if (maybeUser) {
      return firebaseAccountToUser(maybeUser)
    }
    const redirectResult = await getRedirectResult(authInstance).catch(err => {
      console.error(err)
    })
    if (redirectResult?.user == null) {
      await this.login()
      // will redirect, so won't actually return anything
      return null
    }
    return firebaseAccountToUser(redirectResult?.user)
  },

  async login() {
    throwIfNoAuthInstance(authInstance)
    // logout first to ensure a clean login
    await signOut(authInstance)
    const result = await signInWithRedirect(authInstance, authProvider).catch(
      err => {
        console.error(err)
      }
    )
    return result
  },

  async logout() {
    throwIfNoAuthInstance(authInstance)
    await signOut(authInstance)
    await this.login()
  },

  async getToken() {
    if (authInstance?.currentUser == null) {
      return null
    }
    const token = await getIdToken(authInstance?.currentUser)
    if (!token) {
      return null
    }
    return token
  },
}

function firebaseAccountToUser(account) {
  if (!account?.displayName || !account.email) {
    return null
  }
  const id = account.providerData.find(
    provider => provider.providerId === PROVIDER_ID_MICROSOFT
  )?.uid
  return {
    name: account.displayName,
    email: account.email,
    accountId: id,
  }
}

function throwIfNoAuthInstance(authInstance) {
  if (!authInstance) {
    throw new Error('Auth instance not initialized')
  }
}
